<script>
  import {createEventDispatcher} from "svelte";

  export let options //= ["Option 1", "Option 2", "Option 3", "Option 4"];
  $: selected = 'Please select'
  let isOpen = false;
  const disp = createEventDispatcher()


  function toggleDropdown() {
    isOpen = !isOpen;
  }

  function select(option){
    disp('select', {'selected': option})
    selected = option
    isOpen=false
  }

</script>
<div>
<div class="dropdown">
  <button on:click={toggleDropdown}>{selected}</button>
  {#if isOpen}
    <div class="dropdown-content">
      {#each options as option}
        <div class="dropdown-item" on:click={()=>select(option)}>{option}</div>
      {/each}
    </div>
  {/if}
</div>
</div>

<style>
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: block;
    /*position: absolute;*/
    background-color: var(--bg-3);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 7px;
    /*z-index: 1;*/
  }

  .dropdown-item {
    color: var(--fg-2);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    z-index: 3;
  }

  .dropdown-item:hover {
    /*background-color: #f1f1f1;*/
  }
  /*
  button{
    color:rgb(175,240,228);
    background-color:rgb(75,0,128);
  }
  */
</style>
