<script>
  import Panel from "$lib/non_graph_panels.svelte";
  import DynamicScroll from '$lib/dynamic_scroll.svelte'
  import Image from "$lib/image.svelte";
  import {base} from '$app/paths'
  import AtomicEquation from "$lib/atomic_equation.svelte";
  import ScrollContainer from '$lib/scroll_container.svelte'
  import {algebra_equation, algebra_mistakes, algebra_correction_equation, sem_equ_with_mistake,
    mistakes_for_sem
    } from
        "$lib/examples/equations.js";
  import {calculus_equ, calculus_mistakes} from "../examples/equations.js";
  let width='400px'
  let panel_1 = [
    {
      component: Image,
      props: {
        src: `${base}/math_images/wrong_algebra.jpg`,
        alt: "A handwritten solution solving for x starting with $$x^2 -2 = x$$",
        width: width,
      },
      display_text: "You upload your handwritten work.",
    },
    {
      component: AtomicEquation,
      props: {
        equation: algebra_equation,
        mistakes: algebra_mistakes,
        is_student: true,
      },
      display_text: "You receive the mistakes you've made.",

    },
    {
      component: AtomicEquation,
      props: {
        equation: algebra_correction_equation,
      },
      display_text: "You correct your work.",

    },

  ]

  let panel_2 = [
    {
      component: Image,
      props: {
        src: `${base}/math_images/quadratic_submission.jpg`,
        width:width,
      },
      display_text: 'You submit your problem'

    },
    {
      component: AtomicEquation,
      props: {
        equation: calculus_equ,
        mistakes: calculus_mistakes,
      },
      display_text: "You receive feedback"
    },

  ]

  let panel_3 = [
    {
      component: Image,
      props: {
        src: `${base}/math_images/physics_sub.jpg`,
        alt: 'A solution in which the answer is incorrect, an arithmetical error causing 80->90 ',
        width:width,
      },
      display_text: "You upload your work",
    },
    {
      component: AtomicEquation,
      props: {
        equation: sem_equ_with_mistake,
        mistakes: mistakes_for_sem,
      },
      display_text: "You receive our feedback",

    }
  ]




</script>

<!-- <ScrollContainer></ScrollContainer>

utah tech week
make video demo

-->

<p>Would you like instant feedback on your work?</p>

<DynamicScroll component_sets={[panel_1,panel_2,panel_3]}></DynamicScroll>
<!--DynamicScroll components={panel_2}></DynamicScroll-->

<!--<p>We can help!</p>-->
<!--<p>See below the sorts of things with which we can assist you 🤓!</p>-->
<!--<h2>Algebra</h2>-->
<!--<Panel elements={panel_1}></Panel>-->
<!--<h2>Calculus</h2>-->
<!--<Panel elements={panel_2}> </Panel>-->

<!--<h2>Physics</h2>-->
<!--<Panel elements={panel_3}> </Panel>-->

