<script>
    import Icon from "@iconify/svelte";
    export let detail_text;
    export let text;
    export let icon;
    
</script>

<div class="card">

    <div class="image">

        <Icon color="black" class='icon' icon={icon}  width={30} height={30}/>

    </div>

    <div class="text">
        <h1 style="font-weight: bold; font-size: 20px">{text}</h1>
        <p>{detail_text}</p>
    </div>

</div>
  
  
<style>
.card {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Horizontally center the flex items */
  justify-content: center; /* Vertically center the content if the card's height allows */
  height: 45vh;
  margin: auto; /* Center the card itself if within another container */
  text-align: center; /* Ensures text alignment is centered */
  color: white; /* Assuming you want white text */
}

.image {
  display: flex;
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  background-color: white; /* Background color for the icon container */
  width: 80px; /* Set the width for the icon container */
  height: 80px; /* Set the height for the icon container */
  border-radius: 100px; /* Make it circular */
  margin-bottom: 2vh; /* Add some space between the icon container and the text */
}

.text {
  /* Your text styling, already centered text with text-align */
  margin-top: 5vh; /* Adjust top margin as needed */
  margin-bottom: 5vh; /* Adjust bottom margin as needed */
}


</style>