<script>
    import {onMount} from "svelte";
    import {browser} from "$app/environment";

    export let component_sets;
  $: elements = component_sets.map(() => []);
  let parents = [];

  function calculateStyles(index) {
    const top = `${index * 7.5}vh`;
    const height = `${'max-content' - index * 7.5}vh`;
    return `top: ${top}; height: ${height};`;
  }
    onMount(() => {
      if (browser){
        [...document.querySelectorAll('.sticky')].forEach((el, idx) => {
          el.style.setProperty('--idx', idx);
        })
      }
    })
</script>
<style>
  .step-content {
    grid-column: 2;
  }

  .step {
    counter-increment: pos;
    position: sticky;
    grid-column: 1;
    --step-gap: calc(160vh / (var(--step-count) + 2));
    --idx-gap: calc((var(--idx, 1) + 1) * var(--step-gap));
    top: calc(1rem + var(--idx-gap));
    height: calc((var(--step-count) - var(--idx) - 1) * var(--step-gap));
    /*max-height: 100vh;*/
    justify-self: center;
    margin: 0 6ch 0 0;
  }

  .step:before {
    content: counter(pos);
    font-size: 4rem;
    position: absolute;
    opacity: 50%;
    top: 0;
    left: 0;
    transform: translateX(-120%);
  }

  :nth-child(even of .step-name):before {
    right: 0;
    transform: translateX(120%);
  }
</style>

<div class="max-w-prose mx-auto h-screen space-between step-content">
</div>

{#each component_sets as components, outer_index}
<!--ol bind:this={parents[outer_index]} grid grid-cols-[1fr_auto] -->
  <div class="relative max-w-[75ch] mx-auto grid pt-4 gap-20"

      style="--step-count: {components.length+2};">
  {#each components as {component, props, display_text}, inner_index}
    <!--li bind:this={elements[outer_index][inner_index]} -->
    <div class="step" style="--idx: {inner_index===0? -1: inner_index}">
      {#if display_text}
        <h3>{display_text}</h3>
      {/if}
      <!--div class="h-screen"-->
      <div style="position: relative;">
        <svelte:component this={component} {...props}/>
      </div>
      <!--/div-->
    </div>
    <div class="max-w-prose mx-auto h-screen space-between step-content">
    </div>
    <div class="max-w-prose mx-auto h-screen space-between step-content">
    </div>

    <!--/li-->
  {/each}

  </div>
  <!--
  <div class="max-w-prose mx-auto h-screen space-between step-content">
    <p>das</p>
  </div>
  -->
<!--/ol-->
{/each}

