<script>
  import { onMount } from 'svelte';

  let container;
  let elements = [];
  let rowCount = 0;
  const rowCapacity = 10; // Number of elements per row, like in Tetris

  onMount(() => {
    container.addEventListener('scroll', handleScroll);
  });

  function handleScroll() {
    if (container.scrollHeight - container.scrollTop === container.clientHeight) {
      addElements();
    }
  }

  function addElements() {
    for (let i = 0; i < rowCapacity; i++) {
      elements.push({ row: rowCount, col: i });
    }
    rowCount++;
  }
</script>

<style>
  .container {
    /* overflow-y: scroll; */
    height: 400px; /* Fixed height for scrolling */
    position: relative;
  }
  .element {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: lightblue;
    border: 1px solid blue;
  }
</style>

<div bind:this={container} class="container">
  {#each elements as { row, col }}
    <div class="element" style="top: {row * 50}px; left: {col * 50}px;"></div>
  {/each}
</div>