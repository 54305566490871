<script>
  import { slide } from 'svelte/transition';
  import { cubicOut } from 'svelte/easing';
  import { onMount,tick } from 'svelte';

  $: highlighted = 0;
  export let expanded = true; //false; // Passed from the parent
  let delay_threshold = 6000; // 6 seconds
  let shared_time_window = 2000; // 2 seconds
  let current_time;
  let visibility_queue = [];
  let timeout
  export let elements ;
  $: _elements = !elements? [] : elements.map((x,i)=>{return {...x, id:i}});
  $: panel_count=!_elements? 0: _elements.length;
  //let active_panel=0;
  onMount(() => {
    const timer = setInterval(() => {
      current_time = new Date().getTime();
      visibility_queue = visibility_queue.filter(el => current_time - el.time < shared_time_window);
      autoTransition();
    }, 1000);

    return () => clearInterval(timer); // Cleanup
  });


  function shouldShowElement(element) {
    //const time_since_last_transition = visibility_queue.find(el => el.id === element.id)?.time;
    //const should_auto_transition = expanded && (time_since_last_transition === undefined || current_time - time_since_last_transition > delay_threshold);
    const last_transition_time = visibility_queue.find(el => el.id === element.id)?.time;
    return (highlighted === element.id) || (expanded && (!last_transition_time || current_time - last_transition_time > delay_threshold));
  }


  function autoTransition() {
    if (expanded) {
      for (const element of _elements) {
        const last_transition = visibility_queue.find(el => el.id === element.id)?.time;
        if (!last_transition || current_time - last_transition > delay_threshold) {
          clearTimeout(timeout);
          highlighted = element.id;
          break;
        }
      }
    }
  }


  function handleIntroStart(element_id) {
    console.log("Total elements", panel_count)
    visibility_queue.push({ id: element_id, time: new Date().getTime() });
    if (expanded) {
      timeout = setTimeout(() => {
        highlighted = (element_id + 1) % panel_count;
      }, delay_threshold);
    }
  }
</script>


<style>
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
    margin: 5px;
    cursor: pointer;
  }

  .active {
    background-color: blue;
  }
  .control-panel{
    display: flex;
    flex-direction: row;
  }
</style>

<div class="container">
  {#each _elements as element}
    {#if shouldShowElement(element)}
      <div in:slide={{ delay: 0, duration: 600, easing: cubicOut }}
           on:introstart={() => handleIntroStart(element.id)}>
        <!-- Render your component or element here based on
         element.component -->
        <svelte:component this={element.component} {...element.props} ></svelte:component>
        {#if element.hasOwnProperty('display_text')}
          <p>{element.display_text}</p>
        {/if}
      </div>
    {/if}
  {/each}
</div>

<div class="control-panel">
  {#if panel_count && panel_count>0}
    {#each Array(panel_count).fill(0) as _, index}
      <div
          class="circle {index === highlighted ? 'active' : ''}"
          on:click={() => {highlighted=index}}>
      </div>
      {_elements[index].display_text}
    {/each}
  {/if}
</div>
