<script>

  import {base} from '$app/paths'
  import ContactRequest from '$lib/contact_request.svelte'
  import Switch from '$lib/switch.svelte'
  import {default_persistence, has_opened_example} from "$lib/store.js";

  import EquationBlock from '$lib/equation_block.svelte'
  import {
    algebra_correction_equation, algebra_equation,
    algebra_mistakes,
    algebra_student_equation,
    algebra_student_sol_del,
    calculus_equ,
      sol_delta
  } from "$lib/examples/equations.js";
  let experience_phrase = "Experience "
  //let

 let person = 'instructor'
  let width = '400px'

  const properties_list = [
    {
      label: `${experience_phrase} algebra`,
      img_src: `${base}/math_images/wrong_algebra.jpg`,
      equation_desc: "A handwritten solution solving for x starting with $$x^2 -2 = x$$",
      equation: algebra_equation,
      equation_edit_sequence: algebra_correction_equation,
      equation_edit_description: "",
      instructor_mistakes: algebra_mistakes,
      student_src: `${base}/math_images/algebra_submission.jpg`,
      student_solution: algebra_student_equation,
      sol_delta: algebra_student_sol_del,
      show_points: true,
      width: width //"400px"
    },
    {
      label: `${experience_phrase} calculus`,
      img_src: `${base}/math_images/quadratic_submission.jpg`,
      equation_desc: "A handwritten solution to the indefinite integral of $$\\int \\frac{4 x + 3}{ 2x^2 + 13x + 69}$$",
      equation: calculus_equ,
      sol_delta: sol_delta,
      person: person,
      width: width //"400px"
    },
    /*
{
  label: `${experience_phrase} physics`,
  img_src: `${base}/math_images/physics_sub.jpg`,
  equation_desc: "A handwritten solution solving for x involving some speeds",
  equation: sem_sol,
  student_solution: sem_stud_sol,
  sol_delta: sem_delta,
  person: person,
  width: "400px"
}*/
  ];
  let elements = []



  properties_list.forEach(prop => {
    prop.is_visible = false;
    prop.is_expanded = false;
  });

  function observe_element(node, props) {
    console.log("observing", node)
    const observer_options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.target === node && !props.is_expanded) {
          const rect = entry.boundingClientRect;
          if (rect.top <= 0 && rect.bottom >= 0) {
            props.is_expanded = true;
            props.expand = true;
            observer.unobserve(node);
          }
        }
      });
    }, observer_options);

    if (node) {
      observer.observe(node);
    }

    return {
      // Cleanup function
      destroy() {
        observer.unobserve(node);
      }
    };
  }

</script>

<p>Use any book (including your own). </p>
<p>Devise any problem set.</p>
<p>We conform to you not vice-versa.</p>
<p>You need only rely on the conventions regarded as standard.</p>
<p> </p>

{#if $has_opened_example}
  <Switch label="Automatic Transitions" bind:value={$default_persistence} design="multi" options={['off','on']}>

  </Switch>
{/if}

{#each properties_list as props, i}
  <div use:observe_element={props}>
    <EquationBlock bind:this={elements[i]}
                   {...props}
                   on:expand={()=>{
      properties_list[i].is_expanded=true; console.log("Expanded element ", i)
    }
      }
    >
    </EquationBlock>
  </div>
{/each}


<h3>Let us solve help solve your biggest pain point</h3>

<ContactRequest>

</ContactRequest>
<!--
<EquationBlock
    label={experience_phrase + " algebra"}
    img_src={`${base}/math_images/wrong_algebra.jpg`}
    equation_desc={"A handwritten solution solving for x starting with $$x^2 -2 = x$$"}
    equation={algebra_equation}
    equation_edit_sequence={algebra_correction_equation}
    equation_edit_description=""
    instructor_mistakes={algebra_mistakes}
    student_src={`${base}/math_images/algebra_submission.jpg`}
    student_solution={algebra_student_equation}
    sol_delta={algebra_student_sol_del}
    show_points={true}
    width="400px">
</EquationBlock>

>


<EquationBlock
    label={experience_phrase + " calculus"}
    img_src={`${base}/math_images/quadratic_submission.jpg`}
    equation_desc={"A handwritten solution to the indefinite integral of $$\\int \\frac{4 x + 3}{ 2x^2 + 13x + 69}$$"}
    equation={calculus_equ}
    sol_delta={sol_delta}
    person={person}
    width="400px">
</EquationBlock>
<EquationBlock
    label={experience_phrase + " physics"}
    img_src={`${base}/math_images/physics_sub.jpg`}
    equation_desc="A handwritten solution solving for x involving some speeds"
    equation={sem_sol}
    student_solution={sem_stud_sol}
    sol_delta={sem_delta}
    person={person}
>
</EquationBlock>
-->
